import React from "react";

import Layout from "../components/layout";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>This page doesn&apos;t exist, sorry about that!</p>
    <Link to="/">Take me back home.</Link>
  </Layout>
);

export default NotFoundPage;
